/* fonts.css */
@font-face {
  font-family: "Avenir";
  src: url("../../public/fonts/Avenir.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "FuturaBDCN";
  src: url("../../public/fonts/FuturaBDCN.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  color: "#ffffff";
}

@font-face {
  font-family: "Nulshock";
  src: url("../../public/fonts/Nulshock.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
